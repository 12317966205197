import { T } from "@/locales";

export default [
  {
    title: T("序号"),
    dataIndex: "index",
    key: "index",
    customRender: (value, item, index) => {
      return index + 1;
    },
  },
  {
    title: T("订单类型"),
    dataIndex: "type_display",
  },
  {
    title: T("字头"),
    dataIndex: "text",
  },
  {
    title: T("操作"),
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    width: "80px",
  },
];
