<template>
  <div>
    <a-card :title="$t('单据字头')">
      <a-row>
        <a-table size="small" :columns="columns" :dataSource="items" :loading="loading" :pagination="pagination">
          <div slot="action" slot-scope="value, item">
            <a-button icon="edit" size="small" @click="openFormModal(item)">{{ $t("编辑") }}</a-button>
          </div>
        </a-table>
      </a-row>
    </a-card>

    <form-modal v-model="visible" :form="targetItem" @update="update" />
  </div>
</template>

<script>
  import { orderPrefixList } from '@/api/system';
  import columns from './columns.js'

  export default {
    components: {
      FormModal: () => import('./FormModal.vue'),
    },
    data() {
      return {
        columns,
        loading: false,
        items: [],
        pagination: { current: 1, total: 0, pageSize: 16 },

        visible: false,
        targetItem: {},
        form: {},
      };
    },
    methods: {
      initialize() {
        this.list();
      },
      list() {
        this.loading = true;
        orderPrefixList(this.searchForm).then(data => {
          this.pagination.total = data.count;
          this.items = data.results;
        }).finally(() => {
          this.loading = false;
        });
      },
      update(item) {
        this.items.splice(this.items.findIndex(i => i.id == item.id), 1, item);
      },
      openFormModal(item) {
        this.targetItem = { ...item };
        this.visible = true;
      },
    },
    mounted() {
      this.initialize();
    },
  }
</script>

<style scoped>
</style>